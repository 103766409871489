import ArtistHead from 'views/Artist/ArtistHead';
import ArtistSimilarSection from 'views/Artist/ArtistSimilarSection';
import ListenInApp from 'components/ListenInApp';
import PageBody, { ViewName } from 'views/PageBody';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { STATION_TYPE } from 'constants/stationTypes';

export type Props = {
  artistId: number;
  artistName: string;
  artistUrl: string;
  pagePath: string;
};

export default function Similar({
  artistId,
  artistName,
  artistUrl,
  pagePath,
}: Props) {
  const translate = useTranslate();

  const title = translate('Stream Music from Artists Like {artistName}', {
    artistName,
  });

  const social = {
    seedId: artistId,
    seedType: STATION_TYPE.ARTIST,
    url: pagePath,
  };

  return artistId ? (
    <>
      <ArtistHead
        artistId={artistId}
        metaTitle={title}
        pagePath={pagePath}
        seedType={STATION_TYPE.ARTIST}
      />
      <ListenInApp seedId={artistId} seedType="artist" />
      <PageBody
        backLink={artistUrl}
        dataTest={ViewName.ArtistSimilar}
        social={social}
        title={artistName}
      >
        <ArtistSimilarSection
          header={translate('Similar Artists')}
          playedFrom={PLAYED_FROM.PROF_ARTIST_SIMILAR}
          singleRow={false}
          tilesHaveFixedHeight
          tilesInRow={3}
        />
      </PageBody>
    </>
  ) : null;
}
